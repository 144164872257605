import validate from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optio_37a0fd863181721fa1be496d58a5a22c/node_modules/nuxt/dist/pages/runtime/validate.js";
import authentication_45global from "/app/middleware/authentication.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.8.0_@parcel+watcher@2.3.0_@types+node@20.8.6_encoding@0.1.13_eslint@8.51.0_optio_37a0fd863181721fa1be496d58a5a22c/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  authentication_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  authuser: () => import("/app/middleware/authuser.ts"),
  "check-url-client": () => import("/app/middleware/check-url-client.ts"),
  auth: () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.6.0-rc.0_encoding@0.1.13_next-auth@4.21.1_next@13.5.6_@babel+core_cd383f9fc2b34cbec71945fdd4547bcf/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}