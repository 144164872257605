<script lang="ts" async setup>

</script>
<template>
  <div class="grid">
    <div class="col-12">
      <div class="flex flex-wrap overflow-hidden mb py-2">
        <div class="flex-grow-2 flex align-items-center justify-content-start font-bold m-2 px-2 py-0 border-round border-0">
          <div class="w-full">
            <BoxCercaCcnl />
          </div>
        </div>
        <div class="font-bold m-2 pr-4 py-1 border-round  border-0">
          <div
            class="flex flex-wrap align-items-center gap-4 border-0 w-full"
          >
            <!-- <NuxtLink
              to="/ccnl/"
              class="btn-custom inline-flex justify-content-center align-items-center px-3 py-2 w-auto border-radius transition-all transition-duration-200"
            >
              <div class="flex flex-wrap align-items-center gap-3 line-height-1 text-white font-medium text-xl ml-0">
                <img src="/images/home_icone/CCNL_bottone_icona_white.png" alt="Image" class="mx-auto block mb-0" style="width: 25px;">
                Contratti CCNL
              </div>
            </NuxtLink> -->

            <Button
              v-tooltip.bottom="{
                value: 'CCNL',
                hideDelay: 200,
                pt: {
                  root: { class:'pt-4' },
                  arrow: {
                    style: {
                      borderBottomColor: 'var(--primary-color)'
                    },
                    class: 'pt-3 mt-1 w-1'
                  },
                  text: 'bg-primary text-lg font-bold text-white '
                }
              }"
              v-posthog-capture="'cta_sez_ccnl'"
              class="btn-custom inline-flex justify-content-center align-items-center w-auto border-radius transition-all transition-duration-200 shadow-4"
              @click="$router.push('/ccnl/')"
            >
              <div class="flex flex-wrap align-items-center gap-3 line-height-1 text-white font-medium text-xl ml-0">
                <img src="/images/home_icone/CCNL_bottone_icona_white.png" alt="Image" class="h-full mx-auto block mb-0" style="width: 45px;">
              </div>
            </Button>
            <Button
              v-tooltip.bottom="{
                value: 'Integrativi Regionali e Provinciali',
                hideDelay: 200,
                pt: {
                  root: { class:'pt-4' },
                  arrow: {
                    style: {
                      borderBottomColor: 'var(--primary-color)'
                    },
                    class: 'pt-3 mt-1 w-1'
                  },
                  text: 'bg-primary text-lg font-bold text-white '
                }
              }"
              v-posthog-capture="'cta_sez_ccnlreg'"
              class="btn-custom inline-flex justify-content-center align-items-center w-auto border-radius transition-all transition-duration-200 shadow-4"
              @click="$router.push('/ccnlreg/')"
            >
              <div class="flex flex-wrap align-items-center gap-3 line-height-1 text-white font-medium text-xl ml-0">
                <img src="/images/home_icone/ccnlregprov.png" alt="Image" class="h-full mx-auto block mb-0" style="width: 50px;">
              </div>
            </Button>
            <Button
              v-tooltip.bottom="{
                value: 'Guide Pratiche CCNL',
                hideDelay: 200,
                pt: {
                  root: { class:'pt-4' },
                  arrow: {
                    style: {
                      borderBottomColor: 'var(--primary-color)'
                    },
                    class: 'pt-3 mt-1 w-1'
                  },
                  text: 'bg-primary font-medium text-lg font-bold text-white'
                }
              }"
              v-posthog-capture="'cta_sez_guide_pratiche_ccnl'"
              class="btn-custom inline-flex justify-content-center align-items-center w-auto border-radius transition-all transition-duration-200 shadow-4"
              @click="$router.push('/gplccnl/')"
            >
              <div class="flex flex-wrap align-items-center gap-3 line-height-1 text-white font-medium text-xl ml-0">
                <img src="/images/home_icone/gpllavoro_white.png" alt="Image" class="h-full mx-auto block mb-0" style="width: 50px;">
              </div>
            </Button>

            <Button
              v-tooltip.bottom="{
                value: 'Guide Pratiche Istituti',
                hideDelay: 200,
                pt: {
                  root: { class:'pt-4' },
                  arrow: {
                    style: {
                      borderBottomColor: 'var(--primary-color)'
                    },
                    class: 'pt-3 mt-1 w-1'
                  },
                  text: 'bg-primary font-medium text-lg font-bold text-white'
                }
              }"
              v-posthog-capture="'cta_sez_guide_pratiche_istituti'"
              class="btn-custom inline-flex justify-content-center align-items-center w-auto border-radius transition-all transition-duration-200 shadow-4"
              @click="$router.push('/monografie/')"
            >
              <div class="flex flex-wrap align-items-center gap-3 line-height-1 text-white font-medium text-xl ml-0">
                <Icon name="emojione-monotone:books" class="text-white h-full mx-auto block mb-0 border-0 " size="37" style="width: 50px;" />
              </div>
            </Button>
            <Button
              v-tooltip.bottom="{
                value: 'Tabelle accessorie',
                hideDelay: 200,
                pt: {
                  root: { class:'pt-4' },
                  arrow: {
                    style: {
                      borderBottomColor: 'var(--primary-color)'
                    },
                    class: 'pt-3 mt-1 w-1'
                  },
                  text: 'bg-primary font-medium text-lg font-bold text-white'
                }
              }"
              v-posthog-capture="'cta_sez_tabelle_accessorie'"
              class="btn-custom inline-flex justify-content-center align-items-center w-auto border-radius transition-all transition-duration-200 shadow-4"
              @click="$router.push('/lavoro/')"
            >
              <div class="flex flex-wrap align-items-center gap-3 line-height-1 text-white font-medium text-xl ml-0">
                <img src="/images/home_icone/Aliquote_Bottone_Icona_white.png" alt="Image" class="mx-auto block mb-0" style="width: 50px;">
              </div>
            </Button>
            <NuxtLink to="https://sistema.orasilavora.it/" target="_blank" rel="noopener">
              <Button
                v-tooltip.bottom="{
                  value: 'Formulario Lavoro',
                  hideDelay: 200,
                  pt: {
                    root: { class:'pt-4' },
                    arrow: {
                      style: {
                        borderBottomColor: 'var(--primary-color)'
                      },
                      class: 'pt-3 mt-1 w-1'
                    },
                    text: 'bg-primary font-medium text-lg font-bold text-white'
                  }
                }"
                v-posthog-capture="'cta_sez_formulario_lavoro'"
                outline
                class="btn-custom inline-flex py-0 px-1 justify-content-center align-items-center border-radius  transition-all transition-duration-200 shadow-4"
              >
                <div class="flex flex-wrap align-items-center line-height-1 gap-3 text-white font-medium text-xl ml-0 border-0 border-red-500">
                  <img src="/images/home_icone/orasilavora_white.png" alt="Image" class="m-0 p-0  block mb-0 border-0 border-green-700" style="width: 63px;">
                </div>
              </Button>
            </NuxtLink>
            <NuxtLink to="https://equo.redigo.info/direcgi/esechtm?HTGETIN" target="_blank" rel="noopener">
              <Button
                v-tooltip.bottom="{
                  value: 'EQUO - Vertenze',
                  hideDelay: 200,
                  pt: {
                    root: { class:'pt-4' },
                    arrow: {
                      style: {
                        borderBottomColor: 'var(--primary-color)'
                      },
                      class: 'pt-3 mt-1 w-1'
                    },
                    text: 'bg-primary font-medium text-lg font-bold text-white'
                  }
                }"
                v-posthog-capture="'cta_sez_equo'"
                outline
                class="btn-custom inline-flex py-0 px-1 justify-content-center align-items-center border-radius transition-all transition-duration-200 shadow-4"
              >
                <div class="flex flex-wrap align-items-center line-height-1 gap-3 text-white font-medium text-xl ml-0 border-0 border-red-500">
                  <img src="/images/home_icone/equo_white.png" alt="Image" class="m-2 p-0 block mb-0 border-0 border-green-700" style="width: 50px;">
                </div>
              </Button>
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.btn-custom {
    /* background: linear-gradient(to left, var(--purple-600) 50%, var(--purple-700) 50%); */
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    /* border-color: var(--purple-700); */
    display: flex;
    align-items: stretch;
    padding: 0.5rem;

    &:enabled:hover {
        background: linear-gradient(to left, var(--primary-color) 50%, var(--primary-600) 50%);
        background-size: 200% 100%;
        background-position: left bottom;
        /* border-color: var(--surface-overlay); */
		/* border-color: #fff; */
		transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
    }

    &:focus {
        box-shadow: 0 0 0 1px var(--purple-400);
    }
}
.btn-custom-no-background {
    /* background: linear-gradient(to left, var(--purple-600) 50%, var(--purple-700) 50%); */
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    /* border-color: var(--purple-700); */
    display: flex;
    align-items: stretch;
    padding: 0.5rem;

    &:enabled:hover {
        /* background: linear-gradient(to left, var(--primary-color) 50%, var(--primary-600) 50%); */
        background-size: 200% 100%;
        background-position: left bottom;
        /* border-color: var(--surface-overlay); */
		/* border-color: #fff; */
		transform: scale(1.5);
  transition: transform 0.3s ease-in-out;
    }

    &:focus {
        box-shadow: 0 0 0 1px var(--purple-400);
    }
}
</style>
